import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import {HttpClientModule} from '@angular/common/http';
import { MatMenuModule} from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table' 
import { MatPaginatorModule, MatNativeDateModule } from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { UploadComponent } from './views/upload/upload.component';
import { Upload_ContractComponent } from './views/upload_contract/upload_contract.component';
import { Modif_FactComponent } from './views/modif_fact/modif_fact.component';

import { ModifComponent } from './views/modif/modif.component';
import { DeleteComponent } from './views/delete/delete.component';
import { Modif_divComponent } from './views/modif_div/modif_div.component';
import { Delete_divComponent } from './views/delete_div/delete_div.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UploadComponent,
    ModifComponent,
    Modif_divComponent,
    DeleteComponent,
    Delete_divComponent,
    Upload_ContractComponent,
    Modif_FactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatMenuModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatStepperModule, 
    MatChipsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule 
  ],
  providers: [MatDatepickerModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
