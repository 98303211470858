import { Component, OnInit } from "@angular/core";
import * as swal from "sweetalert2";
import { FormControl, Validators} from "@angular/forms";
import { Datacob } from 'src/app/models/datacob';
import { ApiService } from 'src/app/services/api.service';
import { Tabsel } from 'src/app/models/tabsel/tabsel';
import {MatDatepickerModule} from '@angular/material/datepicker';


@Component({
  selector: "app-delete-div",
  templateUrl: "./delete_div.component.html",
  styleUrls: ["./delete_div.component.css"],
})
export class Delete_divComponent implements OnInit {
  consdato:string = "";
  datosvisibles:boolean = false;
  datallevar: DataTRM = {
    fecha: null,
    pais: null,
    coddiv: null,
    valor: null
  };
  
  selector:Tabsel = {conselect: "", confrom: "", conwhere: ""};
  consControl = new FormControl("", Validators.required);
  dateControl = new FormControl("", Validators.required);
  paisControl = new FormControl("", Validators.required);
  divisaControl = new FormControl("", Validators.required);
  valorControl = new FormControl(1.2, Validators.min(0.0001));
  eliminar()
  {
    var d = new Date(this.date.value);
    console.log(d.toISOString());
    console.log(this.paisControl.value);
    console.log(this.divisaControl.value);
    console.log(this.valorControl.value);
    this.eliminarTRM();
  }

eliminarTRM()
{
  var d = new Date(this.dateControl.value);
  this.datallevar.fecha = d.getFullYear().toString() + "-" + (d.getMonth()+1).toString() + "-" +d.getDate().toString();
  this.datallevar.coddiv = this.divisaControl.value;
  this.datallevar.pais = this.paisControl.value;
  

  this.selector.conselect = "DELETE FROM `app_riesgos`.`TRM_CORP`" + " where fecha = '"+this.datallevar.fecha +"' and pais = '"+ this.datallevar.pais + "' and coddiv = '"+this.datallevar.coddiv + "'";
  console.log(this.selector.conselect);
  this.selector.confrom = "";
  this.selector.conwhere = "";
  this.apiService.operacionUp(this.selector).subscribe(
    (data: any) => {},  
    (error) => {
      (swal as any).fire({
        title: "No encontrado",
        text: "No se encontro el registro, por favor verifique",
        icon: "error",
      });
    },
    
    () => {
      (swal as any).fire({
        title: "registro eliminado",
        text: "Se elimino el registro indicado.",
        icon: "info",
      }).then(()=>{
        window.location.href = "/";
      });
    });
  
}
 date: FormControl;
 pais: FormControl;
 divisa: FormControl;
 valor: FormControl;
  constructor(private apiService: ApiService) {}
  ngOnInit() {
    this.date = new FormControl(new Date());
    this.pais = new FormControl(new String());
    this.divisa = new FormControl(new String());
    this.valor = new FormControl(new Number());

  }

  test(){
    console.log(this.date.value);
    console.log(this.dateControl.value);
  }
}
export class DataTRM {
  fecha: string;
  pais: string;
  coddiv: string;
  valor: string;
};
