import { Component, OnInit } from "@angular/core";
import * as swal from "sweetalert2";
import { FormControl, Validators} from "@angular/forms";
import { Datacob } from 'src/app/models/datacob';
import { ApiService } from 'src/app/services/api.service';
import { Tabsel } from 'src/app/models/tabsel/tabsel';
@Component({
  selector: "app-modif_fact",
  templateUrl: "./modif_fact.component.html",
  styleUrls: ["./modif_fact.component.css"],
})
export class Modif_FactComponent implements OnInit {

  buscons:string = "";
  busfactura:string = "";

  consdato:string = "";
  datosvisibles:boolean = false;
  datallevar: Datacob = {
    cons: null,
    pais: null,
    op_esp: null,
    tipo_fact: null,
    tipo_camb: null,
    producto: null,
    tipo_pago: null,
    fecha_riesgo: null,
    fecha_instr: null,
    compania: null,
    monto_cubrir: null,
    moneda: null,
    tipo: null,
    modalidad: null,
    fecha_venc: null,
    fecha_liqu: null,
    cod_clie: null,
    prov_clie: null,
    n_factura: null,
    festivo: null,
  };
  new_factura:string ="";
  dataLlevarAdd: DataCobAdd = {
    cons: null,
    factura: null,
    n_contrato: null,
    spot: null,
    tasa_forward: null,
    contraparte: null,
    observaciones: null,
  };

  selector:Tabsel = {conselect: "", confrom: "", conwhere: ""};
  consControl = new FormControl(1, Validators.min(1));
  modificar()
  {
    this.selector.conselect = "cons,  n_factura";
    this.selector.confrom = "app_riesgos.COB_CORP";
    this.selector.conwhere = "cons = '"+this.buscons +"' and n_factura = '"+this.busfactura + "'";
    this.apiService.readtable(this.selector).subscribe(
      (data: any) => {
            this.datallevar.cons = data[0]["cons"];
            this.datallevar.n_factura  = data[0]["n_factura"];
      },  
      (error) => {
        (swal as any).fire({
          title: "No encontrado",
          text: "No se encuentra un registro con ese consecutivo",
          icon: "error",
        });
      },
      () => {
        this.modificarAdicionales();
      }
    );
  }

  modificarAdicionales(){
    this.selector.conselect = "cons, factura";
    this.selector.confrom = "app_riesgos.COB_ADD";
    this.selector.conwhere = "cons = '"+this.buscons +"' and factura = '"+this.busfactura + "'";
    this.apiService.readtable(this.selector).subscribe(
      (data: any) => {
            this.dataLlevarAdd.cons = data[0]["cons"];
            this.dataLlevarAdd.factura  = data[0]["factura"];
      },  
      (error) => {}
    );
    this.datosvisibles=true;
  }

  cargardata()
  {
    console.log("UPDATE app_riesgos.COB_CORP SET `n_factura` = '"+this.new_factura+"' WHERE `cons` = '"+this.datallevar.cons+"' and `n_factura` = '"+this.dataLlevarAdd.factura+"'");
    this.selector.conselect = "UPDATE app_riesgos.COB_CORP SET `n_factura` = '"+this.new_factura+"' WHERE `cons` = '"+this.datallevar.cons+"' and `n_factura` = '"+this.dataLlevarAdd.factura+"'";
    this.selector.confrom = "";
    this.selector.conwhere = "";
    this.apiService.operacionUp(this.selector).subscribe(
      (data: any) => {
        this.cargarDataAdd();
      },  
      (error) => {
        (swal as any).fire({
          title: "No encontrado",
          text: "No se encuentra un registro con ese consecutivo",
          icon: "error",
        });
      },
      () => {
        this.datosvisibles=false;
        this.datallevar = {
          cons: null,
          pais: null,
          op_esp: null,
          tipo_fact: null,
          tipo_camb: null,
          producto: null,
          tipo_pago: null,
          fecha_riesgo: null,
          fecha_instr: null,
          compania: null,
          monto_cubrir: null,
          moneda: null,
          tipo: null,
          modalidad: null,
          fecha_venc: null,
          fecha_liqu: null,
          cod_clie: null,
          prov_clie: null,
          n_factura: null,
          festivo: null,
        };
        this.consdato = ""
      }
    );
  }

cargardatass()
{
  this.apiService.adddatatable(this.datallevar).subscribe(
    (Colabs: any) => {
      this.cargarDataAdd();
    },
    (error) => {
      (swal as any).fire({
        title: "No Actualizado",
        text: "Algun Dato ingresado esta erroneo, por favor verifique. Error1",
        icon: "error",
      });
    },
    () =>  {
      this.datosvisibles=false;
      this.datallevar = {
        cons: null,
        pais: null,
        op_esp: null,
        tipo_fact: null,
        tipo_camb: null,
        producto: null,
        tipo_pago: null,
        fecha_riesgo: null,
        fecha_instr: null,
        compania: null,
        monto_cubrir: null,
        moneda: null,
        tipo: null,
        modalidad: null,
        fecha_venc: null,
        fecha_liqu: null,
        cod_clie: null,
        prov_clie: null,
        n_factura: null,
        festivo: null,
      };
      this.consdato = ""
    }
  );
}

cargarDataAdd()
{
  this.selector.conselect = "UPDATE app_riesgos.COB_ADD SET `factura` = '"+this.new_factura+"' WHERE `cons` = '"+this.datallevar.cons+"' and `factura` = '"+this.dataLlevarAdd.factura+"'";
  console.log("UPDATE app_riesgos.COB_ADD SET `factura` = '"+this.new_factura+"' WHERE `cons` = '"+this.datallevar.cons+"' and `factura` = '"+this.dataLlevarAdd.factura+"'");
    this.selector.confrom = "";
    this.selector.conwhere = "";
    this.apiService.operacionUp(this.selector).subscribe(
    (Colabs: any) => {
      (swal as any).fire({
        title: "Actualizado",
        text: "Datos Actualizados",
        icon: "info",
      });
    },
    (error) => {
      (swal as any).fire({
        title: "No Actualizado",
        text: "Algun Dato ingresado esta erroneo, por favor verifique. Error2",
        icon: "error",
      });
    },
    () => {
      
    }
  );
}

  constructor(private apiService: ApiService) {}
  ngOnInit() {}
}
export class DataCobAdd {
  cons: string;
  factura: string;
  n_contrato: string;
  spot: string;
  tasa_forward: string;
  contraparte: string;
  observaciones: string;
};
