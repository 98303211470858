import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tabsel } from '../models/tabsel/tabsel';
import { Datacob, DataCobAdd, DataTRM } from '../models/datacob';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  static readtable(selector: Tabsel) {
    throw new Error("Method not implemented.");
  }

  constructor(private httpClient: HttpClient) { }

  
  updateContract(datacoba: DataCobAdd): Observable<any>{
    return this.httpClient.post('https://login.alianzateam.com/api/updatcontrato.php',datacoba);
  }
  readtable(tablsel: Tabsel): Observable<any>{
    return this.httpClient.post('https://login.alianzateam.com/api/chitat_.php', tablsel);
  }
  operacionUp(tablsel: Tabsel): Observable<any>{
    return this.httpClient.post('https://login.alianzateam.com/api/updat_.php', tablsel);
  }
  adddatatable(datacob: Datacob): Observable<any>{
    return this.httpClient.post('https://login.alianzateam.com/api/updat2.php',datacob);
  }
  adddatatablecodadd(datacoba: DataCobAdd): Observable<any>{
    return this.httpClient.post('https://login.alianzateam.com/api/updat3.php',datacoba);
  }
  exkm(): Observable<any>{
    return this.httpClient.get('https://login.alianzateam.com/api/exkm.php');
  }

  upsert_trm(datacoba: DataTRM): Observable<any>{
    return this.httpClient.post('https://login.alianzateam.com/api/upsert_trm.php',datacoba);
  }
  
}
