import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as swal from "sweetalert2";
import { Cob } from "../../models/cob";
import { ExcelService } from "../../services/excel.service";
import { ApiService } from "../../services/api.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DataCobAdd } from "src/app/models/datacob";

@Component({
  selector: "app-upload_contract",
  templateUrl: "./upload_contract.component.html",
  styleUrls: ["./upload_contract.component.css"],
})
export class Upload_ContractComponent implements OnInit {
  filaControl = new FormControl(2, Validators.min(1));
  displayedColumns: string[] = ["symbol0", "symbol1", "symbol2"];
  displayedColumnstext: string[] = ["symbol0", "symbol1", "symbol2"];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ELEMENT_DATA: PeriodicElement[];
  importContacts: Cob[] = [];
  isLinear = false;
  tipoDocumento = "";
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  mostrartabla: boolean = false;
  dataspot: any;
  target: DataTransfer;

  dataLlevarAdd: DataCobAdd = {
    cons: null,
    factura: null,
    n_contrato: null,
    spot: null,
    tasa_forward: null,
    contraparte: null,
    observaciones: null,
  };

  constructor(
    private excelSrv: ExcelService,
    private _formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    this.ELEMENT_DATA = [
      { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
    ];
    this.dataSource = new MatTableDataSource<PeriodicElement>(
      this.ELEMENT_DATA
    );
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
  }

  onFileChange(evt: any) {
    this.target = <DataTransfer>evt.target;
    if (this.target.files.length !== 1)
      throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    this.cargarexcel(reader);
  }

  data: any[];
  datanew: any[] = [];
  cargarexcel(reader: FileReader) {
    this.mostrartabla = true;
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      this.data = <any[]>this.excelSrv.importFromFile(bstr);
      const importedData = this.data.slice(1, -1);

      this.displayedColumnstext = ["Cons.", "Factura", "N. Contrato"];

      console.log("dATA" + this.data[0]);
      console.log("Consecutivo" + this.data[0].indexOf("Consecutivo"));
      console.log("Factura" + this.data[0].indexOf("Factura"));
      console.log("Número Contrato" + this.data[0].indexOf("Número Contrato"));

      var n_cons = this.data[0].indexOf("Consecutivo");
      var n_fact = this.data[0].indexOf("Factura");
      var n_cont = this.data[0].indexOf("Número Contrato");

if(n_cons == -1 || n_fact == -1 || n_cont == -1)
{
alert("Error: alguna Columna no tiene el Nombre Correcto (Consecutivo, Factura, Número Contrato)")
}
else
{

  this.data.splice(0, this.filaControl.value - 1);

  this.data.forEach((fila) => {
    if (fila[0] == null || fila[0] === "") {
    } else {
      this.dataLlevarAdd.cons = fila[n_cons];
      this.dataLlevarAdd.factura = fila[n_fact];
      this.dataLlevarAdd.n_contrato = fila[n_cont];
      this.datanew.push([fila[n_cons], fila[n_fact], fila[n_cont]]);
    }
  });

  this.ELEMENT_DATA = this.datanew;
  this.dataSource = new MatTableDataSource<PeriodicElement>(
    this.ELEMENT_DATA
  );

  this.dataSource.paginator = this.paginator;
  this.importContacts = importedData.map((arr) => {
    const obj = {};

    return <Cob>obj;
  });
}

    };
    reader.readAsBinaryString(this.target.files[0]);
  }

  cargarKM() {
    this.apiService.exkm().subscribe(
      () => {},
      (error) => {
        console.log("error" + error);
      }
    );
    (swal as any).fire({
      title: "Datos Actualizados en KM",
      text: "Datos Actualizados en KM",
      icon: "info",
    });
  }
  cargarnuevo() {
    window.location.href = "/upload_contract";
  }

  num_error:number = 0;
  msg_error:string = "";

  cargardata() {
    this.num_error=0;
    this.msg_error="";
    this.ELEMENT_DATA.forEach((fila) => {
      if (fila[0] == null || fila[0] === "") {
      } else {
        this.dataLlevarAdd.cons = fila[0];
        this.dataLlevarAdd.factura = fila[1];
        this.dataLlevarAdd.n_contrato = fila[2];
        this.apiService.updateContract(this.dataLlevarAdd).subscribe(
          (Colabs: any) => {},
          (error) => {
            console.log("error" + JSON.stringify(error));
            this.msg_error += "Consecutivo: " + fila[0] + ", Factura: " + fila[1] + ". ";
            this.num_error = 1;
          },
          () => {
            if (this.num_error == 0) {
              (swal as any).fire({
                title: "Actualizado",
                text: "Todos los registros fueron Actualizados",
                icon: "success",
              });
            } else {
              (swal as any).fire({
                title: "Actualizado",
                text: "Todos los registros fueron Actualizados a excepcion de "+this.msg_error,
                icon: "info",
              });
            }
          }
        );
      }
    });


    
    

    //window.location.href = "/";
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
