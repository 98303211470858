import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { UploadComponent } from './views/upload/upload.component';
import { ModifComponent } from './views/modif/modif.component';
import { DeleteComponent } from './views/delete/delete.component';
import { Modif_divComponent } from './views/modif_div/modif_div.component';
import { Delete_divComponent } from './views/delete_div/delete_div.component';
import { Upload_ContractComponent } from './views/upload_contract/upload_contract.component';
import { Modif_FactComponent } from './views/modif_fact/modif_fact.component';

const routes: Routes = [
  { path: 'upload-doc', component: UploadComponent },
  { path: 'modif', component: ModifComponent },
  { path: 'delete', component: DeleteComponent },
  { path: 'modif-div', component: Modif_divComponent },
  { path: 'delete-div', component: Delete_divComponent },
  { path: 'upload_contract', component: Upload_ContractComponent },
  { path: 'modif_fact', component: Modif_FactComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
