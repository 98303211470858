import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Tabsel } from '../../models/tabsel/tabsel';
import * as swal from "sweetalert2";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  select:string = "";
  selector:Tabsel = {conselect: "", confrom: "", conwhere: ""};
menu:number = 0;

  constructor(private apiService: ApiService) { 
    this.selector.conselect = "idcargo, compania, grupo";
    this.selector.confrom = "nivapr.cargos";
    apiService.readtable(this.selector).subscribe(
      (data: any) => {
        this.select = data[0]["idcargo"];
      },  
      (error) => {
        this.select = "error1";
      },
      () => {
        this.select += "completo";
      }
    );
  }

  ngOnInit() {
   
      
  }

  act_km(){

    this.apiService.exkm().subscribe(
      () => {
      },
      (error) => {
        /*(swal as any).fire({
          title: "Datos No Actualizados en KM",
          text: "Los Datos No fueron Actualizados en KM - Error en Interface",
          icon: "error",
        });*/
      }
    );
    let timerInterval
        (swal as any).fire({
          title: 'Enviando Solicitud de Actualizaciósn',
          html: 'Enviando Solicitud a KM - Por favor espere <b></b> millisegundos.',
          timer: 2000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            (swal as any).showLoading()
            timerInterval = setInterval(() => {
              const content = (swal as any).getContent()
              if (content) {
                const b = content.querySelector('b')
                if (b) {
                  b.textContent = (swal as any).getTimerLeft()
                }
              }
            }, 100)
          },
          onClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          (swal as any).fire({
            title: "Datos Actualizados en KM",
            text: "Datos Actualizados en KM",
            icon: "info",
          });
        })

  }
  open_trm(){
    this.menu=1;
  }
}
