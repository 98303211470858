import { Component, OnInit } from "@angular/core";
import * as swal from "sweetalert2";
import { FormControl, Validators} from "@angular/forms";
import { Datacob } from 'src/app/models/datacob';
import { ApiService } from 'src/app/services/api.service';
import { Tabsel } from 'src/app/models/tabsel/tabsel';
@Component({
  selector: "app-modif",
  templateUrl: "./modif.component.html",
  styleUrls: ["./modif.component.css"],
})
export class ModifComponent implements OnInit {

  buscons:string = "";
  busfactura:string = "";

  consdato:string = "";
  datosvisibles:boolean = false;
  datallevar: Datacob = {
    cons: null,
    pais: null,
    op_esp: null,
    tipo_fact: null,
    tipo_camb: null,
    producto: null,
    tipo_pago: null,
    fecha_riesgo: null,
    fecha_instr: null,
    compania: null,
    monto_cubrir: null,
    moneda: null,
    tipo: null,
    modalidad: null,
    fecha_venc: null,
    fecha_liqu: null,
    cod_clie: null,
    prov_clie: null,
    n_factura: null,
    festivo: null,
  };
  dataLlevarAdd: DataCobAdd = {
    cons: null,
    factura: null,
    n_contrato: null,
    spot: null,
    tasa_forward: null,
    contraparte: null,
    observaciones: null,
  };

  selector:Tabsel = {conselect: "", confrom: "", conwhere: ""};
  consControl = new FormControl(1, Validators.min(1));
  modificar()
  {
    this.selector.conselect = "cons, pais, op_esp, pais, tipo_fact, tipo_camb, producto, tipo_pago, fecha_riesgo, fecha_instr, compania, monto_cubrir, moneda, tipo, modalidad, fecha_venc, fecha_liqu, cod_clie, prov_clie, n_factura, festivo";
    this.selector.confrom = "app_riesgos.COB_CORP";
    this.selector.conwhere = "cons = '"+this.buscons +"' and n_factura = '"+this.busfactura + "'";
    this.apiService.readtable(this.selector).subscribe(
      (data: any) => {
            this.datallevar.cons = data[0]["cons"];
            this.datallevar.op_esp = data[0]["op_esp"];
            this.datallevar.tipo_fact  = data[0]["tipo_fact"];
            this.datallevar.tipo_camb  = data[0]["tipo_camb"];
            this.datallevar.producto  = data[0]["producto"];
            this.datallevar.tipo_pago  = data[0]["tipo_pago"];
            this.datallevar.fecha_riesgo = data[0]["fecha_riesgo"];
            this.datallevar.fecha_instr = data[0]["fecha_instr"];
            this.datallevar.compania = data[0]["compania"];
            this.datallevar.monto_cubrir = data[0]["monto_cubrir"];
            this.datallevar.moneda  = data[0]["moneda"];
            this.datallevar.tipo  = data[0]["tipo"];
            this.datallevar.modalidad = data[0]["modalidad"];
            this.datallevar.fecha_venc = data[0]["fecha_venc"];
            this.datallevar.fecha_liqu = data[0]["fecha_liqu"];
            this.datallevar.cod_clie = data[0]["cod_clie"];
            this.datallevar.prov_clie  = data[0]["prov_clie"];
            this.datallevar.n_factura  = data[0]["n_factura"];
            this.datallevar.festivo   = data[0]["festivo"];
            this.datallevar.pais   = data[0]["pais"];
      },  
      (error) => {
        (swal as any).fire({
          title: "No encontrado",
          text: "No se encuentra un registro con ese consecutivo",
          icon: "error",
        });
      },
      () => {
        this.modificarAdicionales();
      }
    );
  }

  modificarAdicionales(){
    this.selector.conselect = "cons, factura, n_contrato, spot, tasa_forward, contraparte, observaciones";
    this.selector.confrom = "app_riesgos.COB_ADD";
    this.selector.conwhere = "cons = '"+this.buscons +"' and factura = '"+this.busfactura + "'";
    this.apiService.readtable(this.selector).subscribe(
      (data: any) => {
            this.dataLlevarAdd.cons = data[0]["cons"];
            this.dataLlevarAdd.n_contrato  = data[0]["n_contrato"];
            this.dataLlevarAdd.spot  = data[0]["spot"];
            this.dataLlevarAdd.tasa_forward  = data[0]["tasa_forward"];
            this.dataLlevarAdd.contraparte = data[0]["contraparte"];
            this.dataLlevarAdd.observaciones  = data[0]["observaciones"];
      },  
      (error) => {}
    );
    this.datosvisibles=true;
  }

cargardata()
{
  this.apiService.adddatatable(this.datallevar).subscribe(
    (Colabs: any) => {
      this.cargarDataAdd();
    },
    (error) => {
      (swal as any).fire({
        title: "No Actualizado",
        text: "Algun Dato ingresado esta erroneo, por favor verifique. Error1",
        icon: "error",
      });
    },
    () =>  {
      this.datosvisibles=false;
      this.datallevar = {
        cons: null,
        pais: null,
        op_esp: null,
        tipo_fact: null,
        tipo_camb: null,
        producto: null,
        tipo_pago: null,
        fecha_riesgo: null,
        fecha_instr: null,
        compania: null,
        monto_cubrir: null,
        moneda: null,
        tipo: null,
        modalidad: null,
        fecha_venc: null,
        fecha_liqu: null,
        cod_clie: null,
        prov_clie: null,
        n_factura: null,
        festivo: null,
      };
      this.consdato = ""
    }
  );
}

cargarDataAdd()
{
  this.dataLlevarAdd.cons = this.datallevar.cons;
  this.dataLlevarAdd.factura = this.datallevar.n_factura;
  this.apiService.adddatatablecodadd(this.dataLlevarAdd).subscribe(
    (Colabs: any) => {
      (swal as any).fire({
        title: "Actualizado",
        text: "Datos Actualizados",
        icon: "info",
      });
    },
    (error) => {
      (swal as any).fire({
        title: "No Actualizado",
        text: "Algun Dato ingresado esta erroneo, por favor verifique. Error2",
        icon: "error",
      });
    },
    () => {
      this.apiService.exkm().subscribe(
        () => {},
        (error) => {
          console.log("error" + error);
        }
      );
    }
  );
}

  constructor(private apiService: ApiService) {}
  ngOnInit() {}
}
export class DataCobAdd {
  cons: string;
  factura: string;
  n_contrato: string;
  spot: string;
  tasa_forward: string;
  contraparte: string;
  observaciones: string;
};
