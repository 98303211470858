import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as swal from "sweetalert2";
import { Cob } from "../../models/cob";
import { ExcelService } from "../../services/excel.service";
import { ApiService } from "../../services/api.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Datacob, DataCobAdd } from "src/app/models/datacob";
import { Router } from "@angular/router";
import { strictEqual } from 'assert';

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.css"],
})
export class UploadComponent implements OnInit {
  filaControl = new FormControl(3, Validators.min(1));
  displayedColumns: string[] = [
    "symbol0",
    "symbol1",
    "symbol2",
    "symbol3",
    "symbol4",
    "symbol5",
    "symbol6",
    "symbol7",
    "symbol8",
    "symbol9",
    "symbol10",
    "symbol11",
    "symbol12",
    "symbol13",
    "symbol14",
    "symbol15",
    "symbol16",
    "symbol17",
    "symbol18",
    "symbol19",
    "symbol20",
    "symbol21",
    "symbol22",
    "symbol23",
    "symbol24",
    "symbol25",
    "symbol26",
    "symbol27",
    "symbol28",
    "symbol29",
  ];
  displayedColumnstext: string[] = [
    "symbol0",
    "symbol1",
    "symbol2",
    "symbol3",
    "symbol4",
    "symbol5",
    "symbol6",
    "symbol7",
    "symbol8",
    "symbol9",
    "symbol10",
    "symbol11",
    "symbol12",
    "symbol13",
    "symbol14",
    "symbol15",
    "symbol16",
    "symbol17",
    "symbol18",
    "symbol19",
    "symbol20",
    "symbol21",
    "symbol22",
    "symbol23",
    "symbol24",
    "symbol25",
    "symbol26",
    "symbol27",
    "symbol28",
    "symbol29",
  ];
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ELEMENT_DATA: PeriodicElement[];
  importContacts: Cob[] = [];
  isLinear = false;
  tipoDocumento = "";
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  mostrartabla: boolean = false;
  dataspot: any;
  target: DataTransfer;

  datallevar: Datacob = {
    cons: null,
    pais: null,
    op_esp: null,
    tipo_fact: null,
    tipo_camb: null,
    producto: null,
    tipo_pago: null,
    fecha_riesgo: null,
    fecha_instr: null,
    compania: null,
    monto_cubrir: null,
    moneda: null,
    tipo: null,
    modalidad: null,
    fecha_venc: null,
    fecha_liqu: null,
    cod_clie: null,
    prov_clie: null,
    n_factura: null,
    festivo: null,
  };

  dataLlevarAdd: DataCobAdd = {
    cons: null,
    factura: null,
    n_contrato: null,
    spot: null,
    tasa_forward: null,
    contraparte: null,
    observaciones: null,
  };

  constructor(
    private excelSrv: ExcelService,
    private _formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    this.ELEMENT_DATA = [
      { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
    ];
    this.dataSource = new MatTableDataSource<PeriodicElement>(
      this.ELEMENT_DATA
    );
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
  }

  onFileChange(evt: any) {
    this.target = <DataTransfer>evt.target;
    if (this.target.files.length !== 1)
      throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    this.cargarexcel(reader);
  }

  data: any[];
  cargarexcel(reader: FileReader) {
    this.mostrartabla = true;
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      this.data = <any[]>this.excelSrv.importFromFile(bstr);
      if (
        this.tipoDocumento == "Colombia - Operaciones de Compra" ||
        this.tipoDocumento == "Colombia - Operaciones de Venta" ||
        this.tipoDocumento == "Mexico - Operaciones de Compra" ||
        this.tipoDocumento == "Mexico - Operaciones de Venta" ||
        this.tipoDocumento == "Chile - Operaciones de Compra" ||
        this.tipoDocumento == "Chile - Operaciones de Venta" ||
        this.tipoDocumento == "Nuevo Formato" 
      ) {
        this.dataspot = <any[]>(
          this.excelSrv.importFromFilewithSheet(bstr, "CIERRE-REPORTE")
        );
      }

      const importedData = this.data.slice(1, -1);

      if (this.filaControl.value - 2 < 0) {
        this.displayedColumnstext = this.data[this.filaControl.value - 1];
      } else {
        this.displayedColumnstext = this.data[this.filaControl.value - 2];
      }

      switch (this.tipoDocumento) {
        case "Colombia - Operaciones de Compra":
        case "Colombia - Operaciones de Venta":
        case "Mexico - Operaciones de Compra":
        case "Mexico - Operaciones de Venta":
        case "Chile - Operaciones de Compra":
        case "Chile - Operaciones de Venta":
          this.displayedColumnstext = [
            "Cons.",
            "Op. Esp.",
            "Tipo Fact",
            "Tipo de Cambio",
            "Producto",
            "Tipo Pago",
            "Fecha In. De Riesgo ",
            "Fecha Instrucción",
            "Compañía",
            "Monto a Cubrir",
            "Moneda",
            "Tipo",
            "Modalidad",
            "Fecha Vencimiento",
            "Fecha Liquidación",
            "Proveedor/Cliente",
            "No. Factura",
            "n_contrato",
            "spot",
            "tasa_forward",
            "contraparte",
            "observaciones",
          ];
          break;
        case "Nuevo Formato":
          this.displayedColumnstext = [
            "Cons.",
            "Fecha In. Riesgo ",
            "Fecha Instrucción",
            "Compañía",
            "Clase", //Tipo Fact
            "Categoria", //Producto
            "Monto a Cubrir",
            "Moneda",
            "Cod Proveedor/Cliente",
            "Proveedor/Cliente",
            "No. Factura",
            "Posicion", //Tipo
            "Fecha Vencimiento",
            "Fecha Liquidación",
            "FIX",
             "",
             "",
             "", 
            "spot",
            "tasa_forward",
            "contraparte",
            "Observaciones"
            
          ];
          break;
        
        default:
          this.displayedColumnstext = [
            "symbol0",
            "symbol1",
            "symbol2",
            "symbol3",
            "symbol4",
            "symbol5",
            "symbol6",
            "symbol7",
            "symbol8",
            "symbol9",
            "symbol10",
            "symbol11",
            "symbol12",
            "symbol13",
            "symbol14",
            "symbol15",
            "symbol16",
            "symbol17",
            "symbol18",
            "symbol19",
            "symbol20",
            "symbol21",
            "symbol22",
            "symbol23",
            "symbol24",
            "symbol25",
            "symbol26",
            "symbol27",
            "symbol28",
            "symbol29",
          ];
          break;
      }

      this.data.splice(0, this.filaControl.value - 1);
      console.log(this.data[0]);
      this.cargarSpot();

      this.ELEMENT_DATA = this.data;
      this.dataSource = new MatTableDataSource<PeriodicElement>(
        this.ELEMENT_DATA
      );

      this.dataSource.paginator = this.paginator;
      this.importContacts = importedData.map((arr) => {
        const obj = {};

        return <Cob>obj;
      });
    };
    reader.readAsBinaryString(this.target.files[0]);
  }
  cargarKM()
  {
    this.apiService.exkm().subscribe(
      () => {},
      (error) => {
        console.log("error" + error);
      }
    );
    (swal as any).fire({
      title: "Datos Actualizados en KM",
      text: "Datos Actualizados en KM",
      icon: "info",
    });
  }
  cargarnuevo()
  {
    window.location.href = "/upload-doc";
  }
  cargardata() {
    switch (this.tipoDocumento) {
      case "Colombia - Operaciones de Compra":
      case "Colombia - Operaciones de Venta":
      case "Mexico - Operaciones de Compra":
      case "Mexico - Operaciones de Venta":
      case "Chile - Operaciones de Compra":
      case "Chile - Operaciones de Venta":
        this.ELEMENT_DATA.forEach((fila) => {
          if (fila[0] == null || fila[0] === "") {
          } else {
            switch (this.tipoDocumento) {
              case "Colombia - Operaciones de Compra":
              case "Colombia - Operaciones de Venta":
                this.datallevar.pais = "90";
                break;
              case "Mexico - Operaciones de Compra":
              case "Mexico - Operaciones de Venta":
                this.datallevar.pais = "80";
                break;
              case "Chile - Operaciones de Compra":
              case "Chile - Operaciones de Venta":
                this.datallevar.pais = "CH";
                break;
              default:
                break;
            }
            this.datallevar.cons = fila[0];
            this.datallevar.op_esp = fila[1];
            this.datallevar.tipo_fact = fila[2];
            this.datallevar.tipo_camb = fila[3];
            this.datallevar.producto = fila[4];
            this.datallevar.tipo_pago = fila[5];
            this.datallevar.fecha_riesgo = fila[6];
            this.datallevar.fecha_instr = fila[7];
            this.datallevar.compania = fila[8];
            this.datallevar.monto_cubrir = fila[9];
            this.datallevar.moneda = fila[10];
            this.datallevar.tipo = fila[11];
            this.datallevar.modalidad = fila[12];
            this.datallevar.fecha_venc = fila[13];
            this.datallevar.fecha_liqu = fila[14];
            this.datallevar.prov_clie = fila[15];
            this.datallevar.n_factura = fila[16];
            this.datallevar.festivo = "0";

            this.apiService.adddatatable(this.datallevar).subscribe(
              (Colabs: any) => {},
              (error) => {
                console.log("error" + error);
              }
            );

            this.dataLlevarAdd.cons = fila[0];
            this.dataLlevarAdd.factura = fila[16];
            this.dataLlevarAdd.n_contrato = fila[17];
            this.dataLlevarAdd.spot = fila[18];
            this.dataLlevarAdd.tasa_forward = fila[19];
            this.dataLlevarAdd.contraparte = fila[20];
            this.dataLlevarAdd.observaciones = fila[21];

            this.apiService.adddatatablecodadd(this.dataLlevarAdd).subscribe(
              (Colabs: any) => {},
              (error) => {
                console.log("error" + error);
              }
            );
          }
        });
        (swal as any).fire({
          title: "Actualizado",
          text: "Datos Actualizados",
          icon: "info",
        });

        //window.location.href = "/";

        break;

          case "Nuevo Formato":
            console.log(this.ELEMENT_DATA);
            this.ELEMENT_DATA.forEach((fila) => {
              if (fila[0] == null || fila[0] === "") {
              } else {
                switch (fila[3]) {
                  case "TF CHI":
                  case "TF CHILE":
                  case "TFCHI":
                  case "TFCHILE":
                    this.datallevar.pais = "CH";
                    break;
                  case "TF COL":
                  case "GRASAS":
                    this.datallevar.pais = "90";
                    break;
                  case "TF MEX":
                  case "TFMEX":
                    this.datallevar.pais = "80";
                    break;
                  case "BD CHI":
                  case "BDCHI":
                    this.datallevar.pais = "CH";
                    break;
                  default:
                    alert("Error en un registro: Compañia no es TF CHI, TF COL, GRASAS, TF MEX, BD CHI");
                    break;
                }
                
             
                this.datallevar.cons = fila[0];
                this.datallevar.fecha_riesgo = fila[1];
                this.datallevar.fecha_instr = fila[2];
                this.datallevar.compania = fila[3];
                this.datallevar.tipo_fact = fila[4];
                this.datallevar.producto = fila[5];
                this.datallevar.monto_cubrir = Number.parseFloat(fila[6]).toFixed(2);
                this.datallevar.moneda = fila[7];
                this.datallevar.cod_clie = fila[8];
                this.datallevar.prov_clie = fila[9];
                this.datallevar.n_factura = fila[10];
                this.datallevar.tipo = fila[11];
                this.datallevar.fecha_venc = fila[12];
                this.datallevar.fecha_liqu = fila[13];
                //FIX
                this.datallevar.modalidad = "";
                this.datallevar.op_esp = "";
                this.datallevar.tipo_camb = "";
                this.datallevar.tipo_pago = "";
                this.datallevar.festivo = "0";
                this.apiService.adddatatable(this.datallevar).subscribe(
                  (Colabs: any) => {},
                  (error) => {
                    console.log("error" + error);
                  }
                );
    
                this.dataLlevarAdd.cons = fila[0];
                this.dataLlevarAdd.factura = this.datallevar.n_factura;
                this.dataLlevarAdd.n_contrato = "";
                this.dataLlevarAdd.spot = fila[18];
                this.dataLlevarAdd.tasa_forward = fila[19];
                this.dataLlevarAdd.contraparte = fila[20];
                this.dataLlevarAdd.observaciones = "";
                
                console.log("this.fila");
                console.log(fila);
                console.log("this.datallevar");
                console.log(this.datallevar);
                console.log("ADD");
                console.log(this.dataLlevarAdd);

                this.apiService.adddatatablecodadd(this.dataLlevarAdd).subscribe(
                  (Colabs: any) => {},
                  (error) => {
                    console.log("error" + error);
                  }
                );
              }
            });
            (swal as any).fire({
              title: "Actualizado",
              text: "Datos Actualizados",
              icon: "info",
            });
    
            //window.location.href = "/";
    
            break;
      
      default:
        this.displayedColumnstext = [
          "symbol0",
          "symbol1",
          "symbol2",
          "symbol3",
          "symbol4",
          "symbol5",
          "symbol6",
          "symbol7",
          "symbol8",
          "symbol9",
          "symbol10",
          "symbol11",
          "symbol12",
          "symbol13",
          "symbol14",
          "symbol15",
          "symbol16",
          "symbol17",
          "symbol18",
          "symbol19",
          "symbol20",
          "symbol21",
          "symbol22",
          "symbol23",
          "symbol24",
          "symbol25",
          "symbol26",
          "symbol27",
          "symbol28",
          "symbol29",
        ];
        break;
    }
  }

  cargarSpot() {
    var banco = 0;
    var spot = 0;
    var dataspottmp =  this.dataspot.slice();
    
    dataspottmp.splice(0, 9);
       banco = dataspottmp[0].indexOf("Banco");
       spot = dataspottmp[0].indexOf("Spot");
       
      console.log("banco" + dataspottmp[0].indexOf("Banco"));
      console.log("spot" + dataspottmp[0].indexOf("Spot"));
      
     

    this.dataspot.splice(0, 10);
    
    this.dataspot.forEach((fila, index) => {
      if (fila[banco] == null || fila[banco] === "") {
      } else {
        this.data[index][17] = ""; //no contrato

        console.log("compania:"+this.data[index][3]);
        switch (this.data[index][3]) {
          case "TF CHI":
          case "TF CHILE":
          case "TFCHI":
          case "TFCHILE":
            this.data[index][18] = Number.parseFloat(fila[spot]).toFixed(2);
            this.data[index][19] = Number.parseFloat(fila[spot+4]).toFixed(2);
            break;
          case "TF COL":
          case "TFCOL":
          case "GRASAS":
            this.data[index][18] = Number.parseFloat(fila[spot]).toFixed(2);
            this.data[index][19] = Number.parseFloat(fila[spot+2]).toFixed(2);
            break;
          case "TF MEX":
          case "TFMEX":
            this.data[index][18] = Number.parseFloat(fila[spot]).toFixed(4);
            this.data[index][19] = Number.parseFloat(fila[spot+4]).toFixed(4);
            break;
          default:
            this.data[index][18] = Number.parseFloat(fila[spot]).toFixed(4);
            this.data[index][19] = Number.parseFloat(fila[spot+2]).toFixed(4);
            break;
        }




          

          this.data[index][20] = fila[banco];
        this.data[index][21] = "";
      }
    });
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

