import { Component, OnInit } from "@angular/core";
import * as swal from "sweetalert2";
import { FormControl, Validators} from "@angular/forms";
import { Datacob } from 'src/app/models/datacob';
import { ApiService } from 'src/app/services/api.service';
import { Tabsel } from 'src/app/models/tabsel/tabsel';
import {MatDatepickerModule} from '@angular/material/datepicker';


@Component({
  selector: "app-modif-div",
  templateUrl: "./modif_div.component.html",
  styleUrls: ["./modif_div.component.css"],
})
export class Modif_divComponent implements OnInit {
  consdato:string = "";
  datosvisibles:boolean = false;
  datallevar: DataTRM = {
    fecha: null,
    pais: null,
    coddiv: null,
    codbank: null,
    valor: null
  };
  
  selector:Tabsel = {conselect: "", confrom: "", conwhere: ""};
  consControl = new FormControl("", Validators.required);
  dateControl = new FormControl("", Validators.required);
  paisControl = new FormControl("", Validators.required);
  divisaControl = new FormControl("", Validators.required);
  bancoControl = new FormControl("", Validators.required);
  valorControl = new FormControl(1.2, Validators.min(0.0001));
  modificar()
  {
    var d = new Date(this.date.value);
    console.log(d.toISOString());
    console.log(this.paisControl.value);
    console.log(this.divisaControl.value);
    console.log(this.valorControl.value);
    this.cargarTRM();
  }

cargarTRM()
{
  var d = new Date(this.dateControl.value);
  this.datallevar.fecha = d.getFullYear().toString() + "-" + (d.getMonth()+1).toString() + "-" +d.getDate().toString();
  this.datallevar.coddiv = this.divisaControl.value;
  this.datallevar.pais = this.paisControl.value;
  this.datallevar.codbank = this.bancoControl.value;
  this.datallevar.valor = this.valorControl.value;
  console.log(this.datallevar);
  this.apiService.upsert_trm(this.datallevar).subscribe(
    (Colabs: any) => {
      (swal as any).fire({
        title: "Actualizado",
        text: "Datos Actualizados",
        icon: "info",
      });
    },
    (error) => {
      (swal as any).fire({
        title: "No Actualizado",
        text: "Algun Dato ingresado esta erroneo, por favor verifique.",
        icon: "error",
      });
    }
  );
}
 date: FormControl;
 pais: FormControl;
 divisa: FormControl;
 banco: FormControl;
 valor: FormControl;
  constructor(private apiService: ApiService) {}
  ngOnInit() {
    this.date = new FormControl(Date.now());
    this.pais = new FormControl(new String());
    this.divisa = new FormControl(new String());
    this.banco = new FormControl(new String());
    this.valor = new FormControl(new Number());
    
  }

  test(){
    console.log(this.date.value);
    console.log(this.dateControl.value);
  }
}
export class DataTRM {
  fecha: string;
  pais: string;
  coddiv: string;
  codbank: string;
  valor: string;
};
